import { Close } from "@mui/icons-material";
import "./accounttransferform.scss";
import { useContext, useEffect, useState } from "react";
import moment from "moment";
import { CircularProgress } from "@mui/material";
import { useSelector } from "react-redux";
import { Timestamp, collection, doc, writeBatch } from "firebase/firestore";
import { AuthContext } from "../../../context/AuthContext";
import TransactionContext from "../../../context/transactionContext";
import { db } from "../../../firebase";
import { motion } from "framer-motion";

const AccountTransferForm = (props) => {
  const { showAccountTransferModal, setShowAccountTransferModal } = props;
  // --------------------- context ----------------------------------
  const { setOtherTransactions } = useContext(TransactionContext);
  const { currentUser, onDemo } = useContext(AuthContext);
  // --------------------- States ----------------------------------
  const accounts = useSelector((state) => state.accounts.userAccounts);
  const [dataInput, setDataInput] = useState({
    transferTo: "",
    amount: "",
  });
  const [date, setDate] = useState(moment().format("YYYY-MM-DD"));
  const [isLoading, setIsLoading] = useState(false);
  const currency = useSelector((state) => state.userSettings.currency);
  // --------------------- Handlers ----------------------------------

  // --------------------- Handlers ----------------------------------
  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    const amountValue =
      Math.round(parseFloat(dataInput.amount.replace(/,/gi, "")) * 100) / 100;
    const transferToName = accounts.find(
      (item) => item.id === dataInput.transferTo
    )?.name;
    const transferFromName = showAccountTransferModal.transferFromAccount.name;

    let userId = "";
    let transactionIdTransferTo = "";
    let transactionIdTransferFrom = "";

    // account id: transfer_from
    const transferToTransactionDetails = {
      date: Timestamp.fromDate(new Date(date)),
      amount: amountValue,
      category: "Adjustment",
      vendor: "None",
      description: `Balance transfer to ${transferToName}`,
      accountId: showAccountTransferModal.transferFromAccount.id,
      type: "transfer_to",
    };

    // account id: transfer_to
    const transferFromTransactionDetails = {
      date: Timestamp.fromDate(new Date(date)),
      amount: amountValue,
      category: "Adjustment",
      vendor: "None",
      description: `Balance transfer from ${transferFromName}`,
      accountId: dataInput.transferTo,
      type: "transfer_from",
    };
    // ---------- start: demo only -----------------------
    if (!currentUser && onDemo) {
      userId = "test";
      transactionIdTransferTo = Math.random().toString(36);
      transactionIdTransferFrom = Math.random().toString(36);

      // new transaction for transfer from account
      const transferToObject = {
        ...transferToTransactionDetails,
        id: transactionIdTransferTo,
        userId: userId,
      };
      // new transaction for transfer to account
      const transferFromObject = {
        ...transferFromTransactionDetails,
        id: transactionIdTransferFrom,
        userId: userId,
      };

      setOtherTransactions((prevState) =>
        [...prevState, transferToObject, transferFromObject].sort(
          (item1, item2) => item2.date.seconds - item1.date.seconds
        )
      );
    } // ---------- end: demo only -----------------------

    if (currentUser && !onDemo) {
      const batch = writeBatch(db);

      const transferToDocRef = doc(collection(db, "transactions"));
      batch.set(transferToDocRef, {
        ...transferToTransactionDetails,
        userId: currentUser.uid,
      });

      const transferFromDocRef = doc(collection(db, "transactions"));
      batch.set(transferFromDocRef, {
        ...transferFromTransactionDetails,
        userId: currentUser.uid,
      });

      try {
        await batch.commit();
      } catch (err) {
        console.log(err);
        return;
      }
    }

    setIsLoading(false);
    handleCloseForm();
  };

  const handleInput = (e) => {
    const id = e.target.id;
    let value = e.target.value;

    if (id === "amount") {
      const num = value.replace(/[^0-9$.,]/g, "").replace(/,/gi, "");
      const parts = num.split("."); //split whole number and decimal
      parts[0] = parts[0].split(/(?=(?:\d{3})+$)/).join(","); // split whole number with commas
      value = parts.join(".");
      // value = num.split(/(?=(?:\d{3})+$)/).join(",")
    }

    setDataInput({ ...dataInput, [id]: value });
  };

  const onChangeDate = (e) => {
    const newDate = moment(e.target.value).format("YYYY-MM-DD");
    setDate(newDate);
  };

  const handleCloseForm = () => {
    setShowAccountTransferModal((prevState) => ({ ...prevState, show: false }));
  };

  // pre-select account option
  useEffect(() => {
    if (accounts?.length > 0) {
      if (accounts[0].id !== showAccountTransferModal.transferFromAccount.id) {
        setDataInput((prevState) => ({
          ...prevState,
          transferTo: accounts[0]?.id,
        }));
      } else {
        // if account is less than 2 then ""
        setDataInput((prevState) => ({
          ...prevState,
          transferTo: accounts[1]?.id || "",
        }));
      }
    }
  }, []);
  // lock scrolling
  useEffect(() => {
    document.body.style.overflow = "hidden";

    return () => {
      document.body.style.overflow = "auto";
    };
  }, []);
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{
        opacity: 1,
        transition: { duration: 0.4, ease: [0.36, 0.66, 0.04, 1] },
      }}
      exit={{
        opacity: 0,
        transition: { duration: 0.3, ease: [0.36, 0.66, 0.04, 1] },
      }}
      className="accounttransferform"
    >
      <motion.div
        initial={{ scale: 0.95, opacity: 0 }}
        animate={{
          scale: 1,
          opacity: 1,
          transition: { duration: 0.4, ease: [0.36, 0.66, 0.04, 1] },
        }}
        exit={{
          scale: 0.95,
          opacity: 0,
          transition: { duration: 0.3, ease: [0.36, 0.66, 0.04, 1] },
        }}
        className="formContainer"
      >
        <div className="closeContainer" onClick={handleCloseForm}>
          <Close className="closeIcon" />
        </div>
        <h1>Transfer Balance</h1>
        <div className="inputsContainer">
          <form id="data-form-accounts" onSubmit={handleSubmit}>
            <div className="formInput">
              <label>Transfer from</label>
              <div className="transferFrom">
                {showAccountTransferModal.transferFromAccount.name}
              </div>
            </div>
            <div className="formInput">
              <label>Transfer to</label>
              <select
                id="transferTo"
                name="transferTo"
                value={dataInput["transferTo"]}
                onChange={handleInput}
                className="lightBorder"
                required
              >
                <option disabled value="">
                  {" "}
                  -- select account --{" "}
                </option>
                {accounts.map((account) => {
                  if (
                    account.id !==
                    showAccountTransferModal.transferFromAccount.id
                  ) {
                    return (
                      <option value={account.id} key={account.id}>
                        {account.name}
                      </option>
                    );
                  }
                })}
              </select>
            </div>
            <div className="formInput dateInput">
              <label>Date</label>
              <input
                id="date"
                name="date"
                type="date"
                min={moment(
                  new Date(
                    showAccountTransferModal.transferFromAccount
                      ?.beginningBalanceDate?.seconds * 1000
                  )
                ).format("YYYY-MM-DD")}
                max={moment().format("YYYY-MM-DD")}
                value={date}
                onChange={onChangeDate}
                className="lightBorder"
              />
            </div>
            <div className="formInput">
              <label>Amount</label>
              <div
                className="amountField"
                style={{ "--currency-var": `"${currency.symbol}"` }}
              >
                <input
                  id="amount"
                  name="amount"
                  type="text"
                  inputMode="decimal"
                  pattern="^[+-]?([0-9]{1,3}(,[0-9]{3})*(\.[0-9]+)?|\d*\.\d+|\d+)$"
                  value={dataInput["amount"]}
                  onChange={handleInput}
                  className="lightBorder"
                  required
                />
              </div>
            </div>
            <div className="submitRow">
              <button type="submit" className="submitButton btn-primary">
                {isLoading ? (
                  <CircularProgress size={"1.275rem"} className="spinner" />
                ) : (
                  <>
                    <span>Save</span>
                  </>
                )}
              </button>
            </div>
          </form>
        </div>
      </motion.div>
    </motion.div>
  );
};

export default AccountTransferForm;
