import { Close } from "@mui/icons-material";
import { CircularProgress } from "@mui/material";
import React, { useContext, useEffect } from "react";
import { useState } from "react";
import "./accountsform.scss";
import { useSelector } from "react-redux";
import moment from "moment";
import AccountsContext from "../../../context/AccountsContext";
import ColorPicker from "../../../components/colorpicker/ColorPicker";
import TransactionContext from "../../../context/transactionContext";
import AccountReconciliationForm from "./AccountReconciliationForm";
import { AnimatePresence, motion } from "framer-motion";

const AccountsForm = (props) => {
  const { showAccountsFormModal, setShowAccountsFormModal } = props;
  const { createAccount, updateAccount } = useContext(AccountsContext);
  const { data, incomeData, otherTransactions } =
    useContext(TransactionContext);
  const currency = useSelector((state) => state.userSettings.currency);
  const [date, setDate] = useState(moment().format("YYYY-MM-DD"));
  const [dataInput, setDataInput] = useState({
    name: "",
    group: "General",
    beginningBalance: "",
    endingBalance: "",
  });
  const [selectedColor, setSelectedColor] = useState("#F56565");
  const [isLoading, setIsLoading] = useState(false);
  const [showAccountsRecon, setShowAccountsRecon] = useState(false);
  const [reconcileBalance, setReconcileBalance] = useState(0);
  const groupTypes = [
    "General",
    "Bank",
    "Cash",
    "Investment",
    "Property",
    "Receivable",
  ];

  const [computedEndingBalance, setComputedEndingBalance] = useState(
    showAccountsFormModal.editAccount.endingBalance
  );

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    if (showAccountsFormModal.mode === "add") {
      await createAccount(
        dataInput.name,
        dataInput.group,
        dataInput.beginningBalance,
        date,
        selectedColor
      );
    } else if (showAccountsFormModal.mode === "edit") {
      const adjustment =
        parseFloat(dataInput.endingBalance?.replace(/,/gi, "")) -
        computedEndingBalance;
      if (Math.abs(adjustment) >= 0.01) {
        setIsLoading(false);
        setReconcileBalance(adjustment);
        setShowAccountsRecon(true);
        return; // exit function
      }
      await updateAccount(
        showAccountsFormModal.editAccount.id,
        dataInput.name,
        dataInput.group,
        dataInput.beginningBalance,
        date,
        selectedColor
      );
    }

    setIsLoading(false);
    handleCloseForm();
  };

  const handleInput = (e) => {
    const id = e.target.id;
    let value = e.target.value;
    if (id === "beginningBalance" || id === "endingBalance") {
      const num = value.replace(/[^0-9$.,]/g, "").replace(/,/gi, "");
      const parts = num.split("."); //split whole number and decimal
      parts[0] = parts[0].split(/(?=(?:\d{3})+$)/).join(","); // split whole number with commas
      value = parts.join(".");
      // value = num.split(/(?=(?:\d{3})+$)/).join(",")
    }

    setDataInput({ ...dataInput, [id]: value });
  };

  const handleCloseForm = () => {
    setShowAccountsFormModal((prevState) => ({
      ...prevState,
      show: false,
      mode: "add",
    }));
  };

  const onChangeDate = (e) => {
    const newDate = moment(e.target.value).format("YYYY-MM-DD");
    setDate(newDate);
  };

  // helper function
  const getTransactionValue = (
    transaction,
    type,
    accountBeginningBalanceDate
  ) => {
    const transactionAccountId = transaction.accountId;
    const transactionDate = new Date(
      new Date(transaction.date?.seconds * 1000).setHours(0, 0, 0, 0)
    );
    const currentDate = new Date();
    let amount = 0;

    // check transaction if account is used
    if (transactionAccountId === showAccountsFormModal.editAccount.id) {
      // check transaction if within start date and current date
      if (
        transactionDate >= accountBeginningBalanceDate &&
        transactionDate <= currentDate
      ) {
        // negative amount for expense and transfers to
        if (type === "expense" || type === "transfer_to") {
          amount = -transaction.amount;
        } else if (type === "income" || type === "transfer_from") {
          amount = transaction.amount;
        }
      }
    }

    return amount;
  };

  // pre-fill inputs on edit mode
  useEffect(() => {
    if (showAccountsFormModal.mode === "edit") {
      setDataInput({
        name: showAccountsFormModal.editAccount.name,
        group: showAccountsFormModal.editAccount.group,
        beginningBalance:
          showAccountsFormModal.editAccount.beginningBalance?.toLocaleString(
            "en-US"
          ),
        endingBalance:
          showAccountsFormModal.editAccount.endingBalance?.toLocaleString(
            "en-US"
          ),
      });
      setDate(
        moment(
          new Date(
            showAccountsFormModal.editAccount.beginningBalanceDate.seconds *
              1000
          )
        )?.format("YYYY-MM-DD")
      );
      setSelectedColor(showAccountsFormModal.editAccount.color);
    }
  }, []);

  // changes ending balance when beginning balance is changed
  useEffect(() => {
    if (showAccountsFormModal.mode === "edit") {
      // set a delay after input
      const delayUpdateEndingBal = setTimeout(() => {
        let currentBalance = parseFloat(
          dataInput.beginningBalance?.replace(/,/gi, "")
        );
        const accountBeginningBalanceDate = new Date(
          new Date(date).setHours(0, 0, 0, 0)
        );

        data.forEach((transaction) => {
          currentBalance += getTransactionValue(
            transaction,
            "expense",
            accountBeginningBalanceDate
          );
        });
        incomeData.forEach((transaction) => {
          currentBalance += getTransactionValue(
            transaction,
            "income",
            accountBeginningBalanceDate
          );
        });
        otherTransactions.forEach((transaction) => {
          currentBalance += getTransactionValue(
            transaction,
            transaction.type,
            accountBeginningBalanceDate
          );
        });

        setDataInput((prevState) => ({
          ...prevState,
          endingBalance: currentBalance?.toLocaleString("en-us"),
        }));
        setComputedEndingBalance(currentBalance);
      }, 500);

      return () => clearTimeout(delayUpdateEndingBal);
    }
  }, [dataInput.beginningBalance, date]);

  // lock scrolling
  useEffect(() => {
    document.body.style.overflow = "hidden";

    return () => {
      document.body.style.overflow = "auto";
    };
  }, []);
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{
        opacity: 1,
        transition: { duration: 0.4, ease: [0.36, 0.66, 0.04, 1] },
      }}
      exit={{
        opacity: 0,
        transition: { duration: 0.3, ease: [0.36, 0.66, 0.04, 1] },
      }}
      className="accountform"
    >
      <motion.div
        initial={{ scale: 0.95, opacity: 0 }}
        animate={{
          scale: 1,
          opacity: 1,
          transition: { duration: 0.4, ease: [0.36, 0.66, 0.04, 1] },
        }}
        exit={{
          scale: 0.95,
          opacity: 0,
          transition: { duration: 0.3, ease: [0.36, 0.66, 0.04, 1] },
        }}
        className="formContainer"
      >
        <div className="closeContainer" onClick={handleCloseForm}>
          <Close className="closeIcon" />
        </div>
        <h1>{showAccountsFormModal.mode === "add" ? "Add" : "Edit"} Account</h1>
        <div className="inputsContainer">
          <form id="data-form-accounts" onSubmit={handleSubmit}>
            <div className="formInput">
              <label>Account Name</label>
              <input
                id="name"
                name="name"
                type="text"
                value={dataInput["name"]}
                onChange={handleInput}
                pattern="^(?=[a-zA-Z0-9._ ]{1,20}$)(?!.*[_.]{2})[^_.].*[^_.]$"
                className="lightBorder"
                required
              />
            </div>
            <div className="formInput">
              <label>Type</label>
              <select
                id="group"
                name="group"
                value={dataInput["group"]}
                onChange={handleInput}
                className="lightBorder"
                required
              >
                {groupTypes.map((groupType) => (
                  <option value={groupType} key={groupType}>
                    {groupType}
                  </option>
                ))}
              </select>
            </div>
            <div className="formInput dateInput">
              <label>Start Date</label>
              <input
                id="date"
                name="date"
                type="date"
                value={date}
                onChange={onChangeDate}
                className="lightBorder"
              />
            </div>
            <div className="formInput">
              <label>Starting Balance</label>
              <div
                className="amountField"
                style={{ "--currency-var": `"${currency.symbol}"` }}
              >
                <input
                  id="beginningBalance"
                  name="beginningBalance"
                  type="text"
                  inputMode="decimal"
                  pattern="^[+-]?([0-9]{1,3}(,[0-9]{3})*(\.[0-9]+)?|\d*\.\d+|\d+)$"
                  value={dataInput["beginningBalance"]}
                  onChange={handleInput}
                  className="lightBorder"
                  required
                />
              </div>
            </div>
            {showAccountsFormModal.mode === "edit" && (
              <div className="formInput">
                <label>
                  Current Balance -{" "}
                  {new Date().toLocaleDateString(undefined, {
                    timeZone: "UTC",
                  })}
                </label>
                <div
                  className="amountField"
                  style={{ "--currency-var": `"${currency.symbol}"` }}
                >
                  <input
                    id="endingBalance"
                    name="endingBalance"
                    type="text"
                    inputMode="decimal"
                    pattern="^[+-]?([0-9]{1,3}(,[0-9]{3})*(\.[0-9]+)?|\d*\.\d+|\d+)$"
                    value={dataInput["endingBalance"]}
                    onChange={handleInput}
                    className="lightBorder"
                    required
                  />
                </div>
              </div>
            )}
            <div className="formInput">
              <label>Color</label>
              <ColorPicker
                selectedColor={selectedColor}
                setSelectedColor={setSelectedColor}
              />
            </div>
            <div className="submitRow">
              <button type="submit" className="submitButton btn-primary">
                {isLoading ? (
                  <CircularProgress size={"1.275rem"} className="spinner" />
                ) : (
                  <>
                    <span>Save</span>
                  </>
                )}
              </button>
            </div>
          </form>
        </div>
      </motion.div>
      <AnimatePresence>
        {showAccountsRecon && (
          <AccountReconciliationForm
            setShowAccountsRecon={setShowAccountsRecon}
            reconcileBalance={reconcileBalance}
            accountDetails={{
              ...dataInput,
              id: showAccountsFormModal.editAccount.id,
              beginningBalanceDate: date,
              color: selectedColor,
            }}
            setShowAccountsFormModal={setShowAccountsFormModal}
          />
        )}
      </AnimatePresence>
    </motion.div>
  );
};

export default AccountsForm;
